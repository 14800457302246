html,
body,
#page-wrapper,
#content-wrapper,
.page-content,
#view {
  height: 100%;
  width: 100%;
  overflow-y: initial;
}

#view {
  position: relative;
}

.white-space-normal {
  white-space: normal !important;
}

.logo {
  display: inline;
  max-width: 155px;
  max-height: 55px;
}
.legend .title {
  padding: 0 0.3em;
  margin: 0.5em;
  border-style: solid;
  border-width: 0 0 0 1em;
}
.messages {
  max-height: 50px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.containerNameInput {
  width: 85%;
  border: none;
  background: none;
  border-bottom: 1px solid black;
}

.containerNameInput:active,
.containerNameInput:focus {
  outline: none;
}

#network-legend {
  text-align: center;
}

#network-legend span {
  display: inline;
  font-size: 18px;
}

.header_title_content {
  margin-left: 5px;
}

.form-section-title {
  border-bottom: 1px solid var(--border-form-section-title-color);
  margin-top: 5px;
  margin-bottom: 15px;
  color: var(--text-form-section-title-color);
  padding-left: 0;
}

.form-horizontal .control-label.text-left {
  text-align: left;
  font-size: 0.9em;
}

input[type='checkbox'] {
  margin-top: 1px;
  vertical-align: middle;
}

.md-checkbox input[type='checkbox'] {
  width: 16px;
  height: 16px;
  margin-top: -1px;
}

.md-checkbox input[type='checkbox']:indeterminate + label:before {
  content: '-';
  align-content: center;
  line-height: 16px;
  text-align: center;
}

a[ng-click] {
  cursor: pointer;
}

.space-right {
  margin-right: 5px;
}

.space-left {
  margin-left: 5px;
}

.portainer-disabled-link {
  color: gray;
  pointer-events: none;
}

.portainer-disabled-datatable {
  color: gray;
}

.portainer-disabled-datatable a {
  color: gray;
  pointer-events: none;
}

.datatable-highlighted {
  background-color: var(--bg-item-highlighted-color);
}

.datatable-unhighlighted {
  background-color: var(--bg-item-highlighted-null-color);
}

.service-datatable {
  background-color: var(--bg-item-highlighted-color);
  padding: 2px;
}

.service-datatable thead {
  background-color: var(--bg-service-datatable-thead) !important;
}

.service-datatable tbody {
  background-color: var(--bg-service-datatable-tbody);
}

.fa.green-icon {
  color: #23ae89;
}

.fa.red-icon {
  color: #ae2323;
}

.fa.orange-icon {
  color: #f0ad4e;
}

.fa.white-icon {
  color: white;
}

.fa.blue-icon,
.fab.blue-icon {
  color: var(--blue-2);
}

.text-warning {
  color: #f0ad4e;
}

.widget .widget-body table tbody .image-tag {
  font-size: 90% !important;
  margin-right: 5px;
}

.widget .widget-body table tbody .label-margins {
  margin-left: 5px;
  margin-right: 0;
}

.widget .widget-body table tbody .fit-text-size {
  font-size: 90% !important;
}

.widget .widget-body table thead th {
  font-weight: normal;
}

.widget .widget-body .nowrap-cells tbody td {
  white-space: nowrap;
}

.widget .widget-body table tbody td {
  white-space: normal;
  word-break: break-word;
}

.widget .widget-body table.container-details-table > tbody > tr > td:first-child {
  word-break: normal;
  text-transform: uppercase;
}

.widget .widget-body table.description-table {
  table-layout: fixed;
}

.template-widget {
  height: 100%;
}

.template-widget-body {
  max-height: 86%;
  overflow-y: auto;
}

.blocklist {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.blocklist-item {
  padding: 0.7rem;
  margin-bottom: 0.7rem;
  cursor: pointer;
  border: 1px solid var(--border-blocklist-color);
  border-radius: 2px;
  box-shadow: var(--shadow-box-color);
}

.blocklist-item--disabled {
  cursor: auto;
  background-color: var(--grey-12);
}

.blocklist-item--selected {
  background-color: var(--bg-blocklist-item-selected-color);
  border: 2px solid var(--border-blocklist-item-selected-color);
  color: var(--text-blocklist-item-selected-color);
}

.blocklist-item:hover {
  background-color: var(--bg-blocklist-hover-color);
  color: var(--text-blocklist-hover-color);
}

.blocklist-item-box {
  display: flex;
}

.blocklist-item-line.endpoint-item {
  padding: 4px;
}

.blocklist-item-line {
  display: flex;
  justify-content: space-between;
}

.blocklist-item-logo {
  width: 100%;
  max-width: 60px;
  height: 100%;
  max-height: 60px;
}

.blocklist-item-logo.endpoint-item {
  margin: 10px 4px 0 6px;
}

.blocklist-item-logo.endpoint-item.azure {
  margin: 0 0 0 10px;
}

.blocklist-item-title {
  font-size: 1.8em;
  font-weight: bold;
}

.blocklist-item-title.endpoint-item {
  font-size: 1em;
  font-weight: bold;
}

.blocklist-item-subtitle {
  font-size: 0.9em;
  padding-right: 1em;
}

.blocklist-item-desc {
  font-size: 0.9em;
  padding-right: 1em;
}

.template-note {
  padding: 0.5em;
  font-size: 0.9em;
}

.nopadding {
  padding: 0 !important;
}

.padding-top {
  padding-top: 15px !important;
}

.nomargin {
  margin: 0 !important;
}

.terminal-container {
  width: 100%;
  padding: 10px 0;
}

.interactive {
  cursor: pointer;
}

.custom-header-ico {
  max-width: 32px;
  max-height: 32px;
}

.btn-responsive {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-datatable {
  padding: 2.6px 7.8px 3.9px;
  line-height: 1;
  margin: 0px 0px 0px 0px;
}

@media screen and (min-width: 1107px) {
  .btn-responsive {
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
  }
}

.page-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.simple-box {
  margin-bottom: 80px;
}

.simple-box > div:first-child {
  padding-bottom: 10px;
}

.simple-box-logo {
  display: block;
  margin: auto;
  position: relative;
  width: 240px;
  margin-bottom: 10px;
}

.simple-box-form > div {
  margin-bottom: 25px;
}

.simple-box-form > div:last-child {
  margin-top: 10px;
  margin-bottom: 10px;
}

.panel-body {
  padding-top: 30px;
  background-color: var(--white-color) fff;
}

.user-box {
  margin-right: 25px;
}

.select-endpoint {
  width: 80%;
  margin: 0 auto;
}

#image-layers .btn {
  padding: 0;
}

#image-layers .expand {
  padding-right: 0;
}

.createResource {
  margin-left: 5px;
  font-size: 90%;
}

@media (min-width: 768px) {
  .margin-sm-top {
    margin-top: 5px;
  }
}
@media (min-width: 768px) {
  .pull-sm-left {
    float: left !important;
  }
  .pull-sm-right {
    float: right !important;
  }
  .pull-sm-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .pull-md-left {
    float: left !important;
  }
  .pull-md-right {
    float: right !important;
  }
  .pull-md-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .pull-lg-left {
    float: left !important;
  }
  .pull-lg-right {
    float: right !important;
  }
  .pull-lg-none {
    float: none !important;
  }
}
.pull-none {
  float: none !important;
}

.bootbox-form .bootbox-input-checkbox {
  display: none;
}

.bootbox-form .visible {
  position: initial !important;
  display: initial !important;
  margin-left: 10px !important;
  margin-top: -2px !important;
}

.bootbox-form label {
  padding-left: 0;
}

.bootbox-checkbox-list {
  max-height: 200px;
  overflow-y: auto;
  background-color: var(--white-color);
  border: 1px solid var(--grey-48);
  border-radius: 4px;
}

:root[theme='dark'] .bootbox-checkbox-list,
:root[theme='highcontrast'] .bootbox-checkbox-list {
  background-color: var(--black-color);
}

.small-select {
  display: inline-block;
  padding: 0px 6px;
  margin-left: 10px;
  color: var(--text-small-select-color);
  background-color: var(--bg-small-select-color);
  background-image: none;
  border-radius: 4px;
  font-size: 14px;
}

.bootbox-form .checkbox i {
  margin-left: 21px;
}

.visualizer_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.visualizer_container .node {
  border: 1px dashed var(--blue-2);
  background-color: rgb(51, 122, 183);
  background-color: rgba(51, 122, 183, 0.1);
  border-radius: 4px;
  box-shadow: 0 3px 10px -2px var(--grey-50);
  padding: 15px;
  margin: 5px;
}

.visualizer_container .node .node_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-bottom: 1px solid var(--grey-26);
  padding-bottom: 10px;
}

.visualizer_container .node .node_info .node_platform {
  margin-left: 2px;
  font-size: 16px;
}

.visualizer_container .node .node_info .node_labels {
  border-top: 1px solid var(--grey-26);
  padding-top: 10px;
  margin-top: 10px;
}

.visualizer_container .node .node_info .node_label {
  font-style: italic;
  color: #787878;
}

.visualizer_container .node .tasks {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.visualizer_container .node .tasks .task {
  border: 1px solid var(--grey-6);
  border-radius: 2px;
  box-shadow: 0 3px 10px -2px var(--grey-50);
  padding: 10px;
  margin: 5px;
  font-size: 10px;
}

.visualizer_container .node .tasks .task div {
  padding: 2px;
}

.visualizer_container .node .tasks .task_running {
  border-radius: 4px;
  background-color: rgb(35, 174, 137);
  background-color: rgba(35, 174, 137, 0.2);
}

.visualizer_container .node .tasks .task_stopped {
  border-radius: 4px;
  background-color: rgb(174, 35, 35);
  background-color: rgba(174, 35, 35, 0.2);
}

.visualizer_container .node .tasks .task_warning {
  border-radius: 4px;
  background-color: rgb(240, 173, 78);
  background-color: rgba(240, 173, 78, 0.2);
}

.visualizer_container .node .tasks .task_info {
  border-radius: 4px;
  background-color: rgb(70, 184, 218);
  background-color: rgba(70, 184, 218, 0.2);
}

.visualizer_container .node .tasks .task .service_name {
  text-align: center;
  margin-bottom: 5px;
}

.log_viewer {
  height: 100%;
  overflow-y: scroll;
  color: var(--text-log-viewer-color);
  font-size: 0.85em;
  background-color: var(--bg-log-viewer-color);
}

.log_viewer.wrap_lines {
  white-space: pre-wrap;
}

.log_viewer .inner_line {
  padding: 0 15px;
  cursor: pointer;
  margin-bottom: 0;
}

.log_viewer .inner_line:empty::after {
  content: '.';
  visibility: hidden;
}

.log_viewer .line_selected {
  background-color: var(--bg-log-line-selected-color);
}

.tag:not(.token) {
  padding: 2px 6px;
  color: white;
  background-color: var(--blue-2);
  border: 1px solid #2e6da4;
  border-radius: 4px;
}

.template-envvar {
  padding-bottom: 5px;
}

.line-separator {
  border-bottom: 1px solid var(--grey-26);
  width: 50%;
  margin: 20px auto 10px auto;
}

.modal {
  text-align: center;
  padding: 0 !important;
}

.modal::before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.striked::after {
  border-bottom: 0.2em solid var(--grey-26);
  content: '';
  left: 0;
  margin-top: calc(0.2em / 2 * -1);
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 2;
}

.striketext:before,
.striketext:after {
  background-color: var(--grey-26);
  content: '';
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.striketext:before {
  right: 0.5em;
  margin-left: -50%;
}

.striketext:after {
  left: 0.5em;
  margin-right: -50%;
}

/*bootbox override*/
.modal-open {
  padding-right: 0 !important;
}

.image-zoom-modal .modal-dialog {
  width: 80%;
}

/*!bootbox override*/

/*angular-multi-select override*/
.multiSelect > button {
  min-height: 30px !important;
  background-image: var(--bg-image-multiselect-button);
  border-color: var(--border-multiselect);
  color: var(--text-multiselect);
  background-color: var(--bg-multiselect-color);
}

.multiSelect > button:hover {
  background-image: var(--bg-image-multiselect-hover);
}

.multiSelect .checkboxLayer {
  border-color: var(--border-multiselect-checkboxlayer);
}

.multiSelect .checkBoxContainer {
  background-color: var(--bg-multiselect-checkboxcontainer);
}

.multiSelect .multiSelectItem {
  color: var(--text-multiselect-item);
}

.multiSelect .helperContainer {
  background-color: var(--bg-multiselect-helpercontainer);
}

.multiSelect .multiSelectFocus {
  background-image: var(--bg-image-multiselect);
}

.multiSelect .multiSelectItem:not(.multiSelectGroup).selected {
  background-image: var(--bg-image-multiselect);
  color: var(--white-color);
  border: none;
}

.multiSelect .multiSelectItem:hover,
.multiSelect .multiSelectGroup:hover {
  border-color: var(--grey-3);
  background-image: var(--bg-image-multiselect) !important;
  color: var(--white-color) !important;
}

.multiSelect .tickMark,
.widget .widget-body table tbody .multiSelect .tickMark {
  top: 2px;
  right: 12px;
  font-size: 20px !important;
}
/*!angular-multi-select override*/

/*toaster override*/
#toast-container > div {
  opacity: 0.9;
}
/*!toaster override*/

.monospaced {
  font-family: monospace;
  font-weight: 600;
}

/* json-tree override */
json-tree {
  font-size: 13px;
  color: var(--blue-5);
}

json-tree .key {
  color: var(--blue-3);
  padding-right: 5px;
}

json-tree .branch-preview {
  font-style: normal;
  font-size: 11px;
  opacity: 0.5;
}
/* !json-tree override */

/* uib-progressbar override */
.progress-bar {
  color: var(--text-progress-bar-color);
}
/* !uib-progressbar override */

.loading-view-area {
  height: 85%;
  display: flex;
  align-items: center;
}

/* bootstrap extend */
.input-xs {
  height: 22px;
  padding: 2px 5px;
  font-size: 12px;
  line-height: 1.5; /* If Placeholder of the input is moved up, rem/modify this. */
  border-radius: 3px;
}
/* !bootstrap extend */

/* spinkit override */
.sk-fold {
  width: 57px;
  height: 57px;
}

.sk-fold-cube {
  background-color: white;
}

.sk-fold-cube:before {
  background-color: var(--blue-2);
}
/* !spinkit override */

/* uib-typeahead override */
#scrollable-dropdown-menu .dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
}
/* !uib-typeahead override */

.kubectl-shell {
  display: block;
  text-align: center;
  padding-bottom: 5px;
}

.w-full {
  width: 100%;
}

.flex {
  display: flex;
}

.block {
  display: block;
}

.items-center {
  align-items: center;
}

.space-x-2 > * + * {
  margin-left: 0.5rem;
}

.space-x-3 > * + * {
  margin-left: 0.75rem;
}

.space-x-4 > * + * {
  margin-left: 1rem;
}

.space-y-8 > * + * {
  margin-top: 2rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.m-l-5 {
  margin-left: 5px;
}

.m-l-20 {
  margin-left: 20px;
}

.m-l-30 {
  margin-left: 30px;
}

.m-r-2 {
  margin-right: 2px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.no-margin {
  margin: 0 !important;
}

.no-border {
  border: none;
}

.dispay-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.ml-0 {
  margin-left: 0rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-5 {
  margin-left: 1.5rem;
}

.ml-6 {
  margin-left: 1.75rem;
}

.ml-7 {
  margin-left: 2rem;
}

.ml-8 {
  margin-left: 2.25rem;
}

.text-wrap {
  word-break: break-all;
  white-space: normal;
}

.icon-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.space-x-1 {
  margin-left: 0.25rem;
}

/* used for bootbox prompt with inputType radio */
.form-check.radio {
  margin-left: 15px;
}
