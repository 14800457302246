.wizard-button {
  display: block;
  border: 1px solid rgb(163, 163, 163);
  border-radius: 5px;
  width: 200px;
  height: 300px;
  float: left;
  margin-right: 30px;
  cursor: pointer;
  padding: 25px 20px;
  box-shadow: 0 3px 10px -2px rgb(161 170 166 / 60%);
}

.wizard-button:hover {
  box-shadow: 0 3px 10px -2px rgb(161 170 166 / 80%);
  border: 1px solid #3ca4ff;
  color: #337ab7;
}

.wizard-link {
  color: #000;
}

.wizard-title {
  color: #000;
}

.wizard-button-subtitle {
  color: rgb(112, 112, 112);
  margin-bottom: 30px;
  text-align: center;
}

.wizard-button-title {
  font-size: 12px;
  margin-bottom: 15px;
}

.wizard-link-section {
  margin-top: 15px;
}
