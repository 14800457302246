.sidebar-menu .sidebar-menu-head {
  position: relative;
}

.sidebar-menu .sidebar-menu-head .sidebar-menu-indicator {
  background: none;
  border: 0;
  width: fit-content;
  height: fit-content;
  color: white;
  margin: 0 10px;
  padding: 0;
  position: absolute;
  left: -5px;
}
