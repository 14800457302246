.actionBar .description {
  margin-bottom: 10px;
}

.refresh-environments-button {
  margin-left: 0 !important;
}

.filter-container {
  display: flex;
  justify-content: flex-start;
}

.filter-left {
  margin-left: 10px;
  padding: 10px 0px;
  width: 15%;
  display: inline-block;
}

.filter-right {
  padding: 10px;
  width: 20%;
  right: 0;
  display: inline-block;
  margin-left: auto;
}

.filter-button {
  margin-left: 10px;
  padding: 10px 0px;
  width: 5%;
  display: inline-block;
}

.clear-button {
  display: inline-block;
  border: 0px;
  padding: 10px;
  height: 60px;
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.refresh-button {
  display: inline-block;
  width: fit-content;
}

.kubeconfig-button {
  display: inline-block;
  width: fit-content;
}

.filterSearchbar {
  display: inline-block;
  width: 100%;
  padding-left: 10px;
}
