.tooltip-wrapper {
  display: inline-block;
  position: relative;
}

.tooltip {
  font-family: Montserrat !important;
  background-color: var(--bg-tooltip-color) !important;
  padding: 0.833em 1em !important;
  color: var(--text-tooltip-color) !important;
  border: 1px solid var(--border-tooltip-color) !important;
  border-radius: 0.14285714rem !important;
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15) !important;
  max-width: 200px;
  text-align: center;
  font-size: 12px !important;
  font-weight: 400;
}

.icon {
  margin-left: 5px;
  font-size: 1.3em;
}
